import React from 'react';
import '../App.css';
import Login from '../components/Login';
import Footer from '../components/Footer'

function Home() {
  return (
    <div className="home-background">
      <div className="center-container">
        <h1 className="title-text">
          Welcome to <span className="brand-name">WealthHex.io</span>
        </h1>
        <p className="description-text">
          WealthHex is a dynamic ecosystem of interconnected brands delivering transformative solutions in AI, customer engagement, and business intelligence. Together, we empower businesses to unlock their full potential.
        </p>
        <div className="brands-container">

          <h2 className="section-title">Our Commitment to Security</h2>
          <p className="description-text">
            At WealthHex, we understand the importance of data privacy and security. That's why our entire platform is <strong>HIPAA certified</strong>, ensuring compliance with the highest standards for industries like healthcare and beyond.
          </p>
          <h2 className="section-title">Our Brands:</h2>
          <ul className="brand-list">
          <li>
              <a href="http://aitellcom.com" className="brand-link">Ai Tellcom</a> — Is a is a premier offshore satellite phone company specializing in providing reliable and encrypted communication solutions for remote and maritime environments. With a robust global satellite network, we ensure seamless connectivity in even the most isolated locations. Prioritizing security, our state-of-the-art devices feature end-to-end encryption, safeguarding sensitive data and communications. Trusted by explorers, businesses, and governments, we deliver unmatched reliability, privacy, and 24/7 support.
            </li>
            <li>
              <a href="http://signalaiml.com" className="brand-link">Signal AiML</a> — A comprehensive communication platform offering seamless integration of push notifications, email, SMS, and call center connectivity. Designed to streamline customer engagement and operational efficiency, Signal AiML ensures businesses can deliver timely, actionable messages across multiple channels, empowering teams to stay connected and responsive to customer needs.
            </li>
            <li>
              <a href="http://calendaraiml.com" className="brand-link">Calendar AiML</a> — Is a professional scheduling software designed to streamline time management for busy professionals. Powered by advanced AI, it integrates seamlessly with Google, AWS, and Outlook, real-time updates, automated task prioritization, and conflict detection. With smart notifications, customizable features, and robust security, Calendar AiML simplifies complex scheduling needs. Ideal for individuals and teams, it ensures efficient collaboration, time zone adjustments, and optimized workflows for maximum productivity.
            </li>
            <li>
              <a href="http://whisperaiml.com" className="brand-link">Whisper AiML</a> — A cutting-edge AI-driven platform delivering real-time transcription and translation capabilities. By feeding the latest data into our intelligent bots, Whisper AiML ensures unparalleled accuracy and relevance in customer interactions and technical support, enabling informed decision-making and seamless user experiences.
            </li>
            <li>
              <a href="http://chatwithmywebsite.com" className="brand-link">Chat with My Website</a> — An intuitive entry-level business chatbot designed to streamline customer engagement. Perfect for businesses looking to enhance their online presence, this solution provides an easy-to-deploy, intelligent assistant to handle customer queries efficiently, ensuring consistent and reliable support for growing enterprises.
            </li>
            <li>
              <a href="http://embedgpt.chatwithaiml.com" className="brand-link">EmbedGPT</a> — A fully deployable Shopify app designed to revolutionize e-commerce operations. With seamless GraphQL integration for real-time updates and advanced PostgreSQL vector databases, EmbedGPT delivers unparalleled performance and efficiency. This all-in-one solution empowers businesses to enhance customer experiences, optimize workflows, and stay ahead in the competitive e-commerce landscape with minimal effort.
            </li>
            <li>
              <a href="http://sattelaiml.com" className="brand-link">SatTel Aiml</a> —  Is a USA-based satellite telephone company providing reliable, secure, and global communication solutions for remote and critical environments. With cutting-edge technology, we offer seamless connectivity, crystal-clear voice calls, and data services anywhere on the planet. Trusted for our encrypted networks and robust devices, SattelAiML supports explorers, businesses, and emergency services with 24/7 customer support and unparalleled reliability, ensuring you stay connected no matter where you are.
            </li>
            <li>
              <a href="http://globalsimaiml.com" className="brand-link">Global Sim AiML</a> —  Is an innovative global SIM card solution designed to keep travelers and businesses seamlessly connected worldwide. Powered by advanced AI and machine learning, it provides reliable, cost-effective mobile data and voice services across 200+ countries. With intelligent network switching, real-time usage tracking, and robust security features, it ensures optimal connectivity and transparency. Trusted for its flexibility and ease of use, Global Sim AiML is the ultimate choice for global communication.
            </li>
            <li>
             <a href="http://chatgptaiml.com" className="brand-link">ChatGPT AiML</a> — Our general intelligence, free AI chatbot designed to provide seamless interactions and deliver intelligent responses for all users.
            </li>
            <li>
              <em>Coming Soon:</em> <strong>howdoi.pro</strong> and <strong>howdoi.app</strong> — AI-enhanced productivity solutions.
            </li>
          </ul>
        </div>
        {/* Aqua Restore Pro Live Example */}
        <div className="live-example-container">
          <h2 className="section-title">A Live Example of Our Work</h2>
          <p className="description-text">
            At <strong>WealthHex</strong>, we don't just deliver cutting-edge solutions — we implement them to drive measurable results for our partners. One such success story is 
            <a href="http://aquarestorepro.com" className="brand-link"> Aqua Restore Pro</a>, where we handle their entire business ecosystem, including:
          </p>
          <ul className="service-list">
            <li>Scheduling and call center operations</li>
            <li>Customer communications (email, SMS, push notifications)</li>
            <li>Comprehensive website design and management</li>
            <li>Online advertising and Facebook page management</li>
            <li>End-to-end marketing and lead generation</li>
          </ul>
          <p className="description-text">
            By entrusting WealthHex with their business processes, Aqua Restore Pro has streamlined operations, enhanced customer satisfaction, and achieved consistent growth in their market.
          </p>
        </div>

        {/* Login Section */}
        <div className="login-container">
          <h2 className="section-title">Experience the Platform</h2>
          <p className="login-prompt">Please log in to explore our ecosystem:</p>
          <Login />
        </div>
 {/* Footer Section */}
 <Footer /> {/* Render the Footer here */}
      </div>
    </div>
  );
}

export default Home;
